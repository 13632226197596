<template>
  <div>
    <h1 class="d-sr-only">{{ title }}</h1>
    <p class="d-sr-only" v-text="description" />
    <v-container
      ref="anonimouscontainer"
      v-if="!isAuthenticated"
      class="anonymous mt-7 mt-sm-11 py-0"
    >
      <LogoButtons />

      <ServiceButtons class="mt-10 mt-sm-14" :disabledButtons="[10002]" />
    </v-container>

    <v-container class="carousel mt-9 mt-sm-7 mb-8 mb-sm-9">
      <v-row>
        <v-col cols="12" md="8">
          <component
            v-if="carouselProposals.length > 0"
            :is="carouselMode"
            :title="carouselTitle"
            :proposals="carouselProposals"
            paginationClass="pagination-carousel"
          />
        </v-col>
        <v-col cols="12" md="4">
          <WidgetSlider
            ref="widgetSlider"
            paginationClass="pagination-widget"
            class="mt-4 mt-md-0 widget-slider"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="headerProposals.length > 0"
      class="header py-0 mb-16 mb-sm-8"
    >
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="3"
        :md="3"
        :lg="3"
      />
    </v-container>
    <div
      class="grey lighten-1"
      v-if="middleProposals.length > 0"
      v-show="!hiddenCrm['middle']"
    >
      <v-container class="middle py-0 py-3 mb-sm-12">
        <component
          :is="middleMode"
          :title="middleTitle"
          :proposals="middleProposals"
          :sort="category.metaData.template_model.MIDDLE_SORT"
          paginationClass="pagination-middle"
          @no-proposals-data="hiddenCrm['middle'] = true"
        />
      </v-container>
    </div>
    <v-container
      class="footer py-0 mb-12 mb-sm-12"
      v-if="footerProposals.length > 0"
    >
      <component
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        paginationClass="pagination-footer"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <div
      class="grey lighten-1"
      v-if="footer2Proposals.length > 0"
      v-show="!hiddenCrm['crm']"
    >
      <v-container>
        <ProductListSlider
          class="py-0"
          :layout="crmLayout"
          :shuffle="true"
          paginationClass="pagination-suggested"
          position="crm_consigliati_per_te"
          @no-proposals-data="hiddenCrm['crm'] = true"
        >
          <template v-if="crmLayout == 26">
            <h2>I prodotti <strong>più venduti</strong></h2>
          </template>
          <template v-else>
            <div class="d-flex align-center justify-space-beetween">
              <h2>I miei <strong>preferiti</strong></h2>
              <v-spacer />
            </div>
          </template>
        </ProductListSlider>
        <div class="d-flex justify-end">
          <v-btn
            v-if="crmLayout != 26"
            link
            text
            class="primary--text text-none justify-end"
            to="/profile/preferred"
          >
            mostra tutti <v-icon>$arrowForward</v-icon>
          </v-btn>
        </div>
      </v-container>
    </div>

    <v-container class="middle py-3">
      <component
        :is="footer2Mode"
        :title="footer2Title"
        :proposals="footer2Proposals"
        paginationClass="pagination-footer2"
        @no-proposals-data="hiddenCrm['footer2'] = true"
      />
    </v-container>

    <v-container class="py-0 px-3 px-sm-auto  mb-7">
      <NewsletterHorizontal />
    </v-container>
    <FabButton />
  </div>
</template>

<style scoped lang="scss">
.anonymous {
  // fix for low width mobile phones (i.e. Samsung S8)
  .row {
    justify-content: center;
    @media (max-width: 311px) {
      justify-content: space-between !important;
      ::v-deep .logo {
        margin: 0 5px !important;
      }
    }
  }
}
</style>

<script>
import split from "lodash/split";
import get from "lodash/get";
import ServiceButtons from "@/components/buttons/ServiceButtons.vue";
import LogoButtons from "@/components/buttons/LogoButtons.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import WidgetSlider from "@/components/widget/WidgetSlider.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";
import { mapProposalComponent } from "~/service/ebsn.js";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue"),
    ServiceButtons,
    ProductListSlider,
    NewsletterHorizontal,
    WidgetSlider,
    LogoButtons,
    FabButton: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/buttons/FabButton.vue")
        : import("@/components/buttons/Noop.vue")
  },
  data() {
    return {
      slides: {},
      key: 1,
      hiddenCrm: {}
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    },
    hideContainer(name) {
      //used to fix slider parent container visibility if no cards inside slider
      this.hidden[name] = true;
    }
  },
  computed: {
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    crmLayout() {
      return this.hasFavorites ? 29 : 26;
    },
    description() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    }),
    ...mapProposalComponent({
      carousel: "carousel",
      header: "header",
      middle: "middle",
      footer: "footer",
      footer2: "footer2"
    })
  },
  mounted() {
    global.EventBus.$on("login", () => {
      if (this.$vuetify.breakpoint.xs) {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  }
  // ,
  // mounted() {
  //   var LogoButtonsComponent = Vue.extend(LogoButtons);
  //   this.$refs.anonimouscontainer.appendChild(
  //     new LogoButtonsComponent().$mount().$el
  //   );

  //   var ServiceButtonsComponent = Vue.extend(ServiceButtons);
  //   this.$refs.anonimouscontainer.appendChild(
  //     new ServiceButtonsComponent().$mount().$el
  //   );
  // }
};
</script>
