<template>
  <div class="slider widget-slider" :key="componentKey">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide v-for="giftCode in giftCodes" :key="giftCode.id">
        <gift-code-widget :giftCertificate="giftCode" @add="reload" />
      </swiper-slide>
      <swiper-slide v-if="giftCodes.length == 0">
        <autodiscovery-widget />
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && giftCodes.length > 1"
      :id="`widget-slider-pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && giftCodes.length > 1"
      :id="`widget-slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && giftCodes.length > 1"
      :id="`widget-slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>
<script>
import GiftCodeWidget from "./GiftCodeWidget.vue";
import AutodiscoveryWidget from "./AutodiscoveryWidget.vue";

import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import userService from "~/service/userService";

import { mapGetters } from "vuex";
import get from "lodash/get";
export default {
  name: "WidgetSlider",
  props: {
    paginationClass: { type: String, default: "swiper-pagination-widget" },
    cols: { type: Number, default: 1 },
    sm: { type: Number, default: 1 },
    md: { type: Number, default: 1 },
    lg: { type: Number, default: 1 },
    showArrows: { type: Boolean, default: true },
    showBullets: { type: Boolean, default: true }
  },
  components: { AutodiscoveryWidget, GiftCodeWidget },
  mixins: [login, deliveryReactive],
  data() {
    return {
      giftCodes: [],
      componentKey: 0,
      swiperRef: null,
      swiperOption: {
        spaceBetween: 20,
        autoplay: {
          disableOnInteraction: false
        },
        watchOverflow: true,
        pagination: {
          el: `#widget-slider-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#widget-slider-prev-${this.paginationClass}`,
          nextEl: `#widget-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          600: {
            slidesPerView: this.cols
          },
          960: {
            slidesPerView: this.sm
          },
          1264: {
            slidesPerView: this.md
          },
          1904: {
            slidesPerView: this.lg
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    async reload() {
      try {
        let autoAssignedGifts = await userService.listGiftCertificate("auto");
        let manualAssignedGifts = await userService.listGiftCertificate(
          "manual"
        );
        this.giftCodes = [...autoAssignedGifts, ...manualAssignedGifts].filter(
          gift =>
            !get(
              gift,
              "categoryProposal.metaData.category_proposal_type.HIDE_IN_HOME"
            ) == true
        );
      } catch (e) {
        this.giftCodes = [];
      }
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.reload();
    }
  },
  watch: {
    giftCodes() {
      this.componentKey += 1;
      this.$refs.swiperRef.swiperInstance?.slideTo(0);
    }
    // ,
    // isAuthenticated(newVal, oldVal) {
    //   if (newVal == true && newVal != oldVal) {
    //     this.reload();
    //   }
    // }
  }
};
</script>
