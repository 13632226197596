<template>
  <div
    class="default--text text--darken-1 d-flex text-center flex-column justify-center primary lighten-3 h-100 px-3"
  >
    <div class="text-h3 text-lg-h2 font-weight-bold mb-2 mb-lg-0">
      {{ $t("addresses.autoDiscovery.title") }}
    </div>
    <div class="text-body-2">
      {{ $t("addresses.autoDiscovery.description") }}
    </div>
    <div
      class="d-flex mt-5 flex-column"
      :class="{ 'w-50 mx-auto': $vuetify.breakpoint.sm }"
    >
      <vue-google-autocomplete
        id="map"
        classname="autodiscovery-widget"
        ref="address"
        :placeholder="$t(placeholder)"
        :enable-geolocation="true"
        v-on:placechanged="getAddressData"
        country="it"
        v-if="googleEnabled"
      >
      </vue-google-autocomplete>
      <v-btn depressed class="mt-3 primary" @click="goToServiceAutodiscovery">{{
        $t("widget.serviceDiscovery.verify")
      }}</v-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
.autodiscovery-widget {
  background-color: white;
  line-height: 20px;
  padding: 10px;
  border-radius: $text-field-border-radius;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    h2 {
      line-height: 30px !important;
    }
  }
}
</style>
<script>
import { mapActions } from "vuex";
import AddressService from "~/service/addressService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import gmapsInit from "../buttons/gmaps";
import includes from "lodash/includes";

var google;

export default {
  name: "AutodiscoveryWidget",
  components: { VueGoogleAutocomplete },
  props: {
    placeholder: {
      type: String,
      default: "addresses.autoDiscovery.placeholder"
    }
  },
  data() {
    return {
      warehouses: [],
      lockers: [],
      dataValidated: {},
      deliveryServiceAvailable: null,
      results: false,
      googleEnabled: false,
      addressData: null,
      placeResult: null
    };
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, placeResult) {
      try {
        this.dataValidated = await AddressService.isValidAddress({
          country: 1,
          city: addressData.locality,
          postalcode: addressData.postal_code,
          address1: addressData.route,
          addressNumber: addressData.street_number,
          gpsLongitude: addressData.longitude,
          gpsLatitude: addressData.latitude,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          province: this.getProvince(placeResult)
        });
        this.addressData = addressData;
        this.placeResult = placeResult;
        this.deliveryServiceAvailable = true;
      } catch (err) {
        this.deliveryServiceAvailable = false;
      }
      //lookup drive
      try {
        this.warehouses = await AddressService.getAddressByDeliveryService(
          1,
          addressData.latitude,
          addressData.longitude,
          global.config.proximityDefaultDistance
        );
      } catch (err) {
        console.log(err);
      }
      // try {
      //   //lookup locker
      //   this.lockers = await AddressService.getAddressByDeliveryService(
      //     2,
      //     addressData.latitude,
      //     addressData.longitude,
      //     global.config.proximityDefaultDistance
      //   );
      // } catch (err) {
      //   console.log(err);
      // }
      this.results = true;
    },

    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },
    goToServiceAutodiscovery() {
      if (this.results) {
        this.$router.push({
          name: "Page",
          params: {
            pageName: "service-discovery",
            additionalData: {
              addressString: this.$refs.address.$el.value,
              addressData: this.addressData,
              placeResult: this.placeResult
            }
          }
        });
      }
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
