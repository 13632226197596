<template>
  <v-card :to="link" height="100%" @click.prevent="handleClick">
    <v-img
      v-if="srcMobile"
      :src="srcMobile"
      :alt="name"
      height="100%"
      class="widget"
    >
      <v-btn
        v-if="isAssignable && userGiftCertificateId != null"
        outlined
        depressed
        color="primary"
        min-width="80"
        @click.stop.prevent="remove(userGiftCertificateId)"
        >{{ $t("checkout.giftCodeRemove") }}
      </v-btn>
      <v-btn
        v-else-if="isAssignable"
        outlined
        depressed
        color="primary"
        min-width="80"
        @click.stop.prevent="add"
        >{{ $t("checkout.giftCodeUseNow") }}</v-btn
      >
    </v-img>
    <div
      v-else
      class="certificate-card d-flex flex-column justify-space-around align-center"
      :class="cssClass"
      outlined
    >
      <span class="icon-circle">
        <v-icon>{{ icon }}</v-icon>
      </span>
      <div class="text-body-1 font-weight-bold">
        <!-- :class="mode == 'full' ? 'text-body-1' : 'text-body-2'" -->
        {{ name }}
      </div>
      <div
        v-if="!hideEndDate"
        class="expiration text-caption grey lighten-4 my-2"
      >
        <span class="font-weight-bold text-uppercase">{{
          $t("checkout.expires")
        }}</span
        >: {{ $dayjs(giftCertificate.endDate).format("DD MMM YYYY") }}
      </div>
      <v-btn
        v-if="isAssignable && userGiftCertificateId != null"
        outlined
        depressed
        color="primary"
        min-width="80"
        @click.stop.prevent="remove(userGiftCertificateId)"
        >{{ $t("checkout.giftCodeRemove") }}
      </v-btn>
      <v-btn
        v-else-if="isAssignable"
        outlined
        depressed
        color="primary"
        min-width="80"
        @click.stop.prevent="add"
        >{{ $t("checkout.giftCodeUseNow") }}</v-btn
      >
    </div>
  </v-card>
</template>
<style lang="scss">
.widget {
  .v-responsive__content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .v-btn {
    margin-bottom: 20px;
    background-color: #fff;
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftCodeWidget",
  mixins: [giftCertificate]
};
</script>
